import React, { useState, useContext, useEffect } from "react"
import MainLayout from "../layouts/Main"
import Header from "components/Common/Header"
import PageMetadata from "../components/Common/PageMetadata"
import FeaturedArticlesSidebar from "../components/FeaturedArticlesSidebar"
import getFeaturedArticles from "../utils/getFeaturedArticles"
import { graphql } from "gatsby"
import ColumnTitle from "components/UI/ColumnTitle"
import Card from "components/UI/Card"
import List from "components/UI/List"
import BeatLoader from "react-spinners/BeatLoader"
import StockPriceChart from "components/Chart/StockPriceChart"
import { parseISO, closestIndexTo } from "date-fns"
import sort from "../utils/sort"
import FirebaseContext from "components/Common/FirebaseContext"
import Button from "components/UI/Button"
import t from "theme"

//uses GraphQL back reference - which automatically links articles back to company
export const query = graphql`
    query($ticker: String!) {
        selectedCompany: contentfulCompany(ticker: { eq: $ticker }) {
            ...CompanyFields
            timeline {
                headline
                date
                event {
                    event
                }
                link
                cards {
                    description
                    type
                    internalLink {
                        __typename
                        slug
                        headline

                        sortTimestamp
                        question {
                            question
                        }
                    }
                    externalLink
                }
            }
        }
    }
`

const TimelinePage = ({ pageContext: { commonData, ticker }, data: { selectedCompany, selectedPrice }, location }) => {
    const firebase = useContext(FirebaseContext)
    const featuredArticles = getFeaturedArticles(commonData, ticker)
    const timelineEvents = selectedCompany.timeline

    const [stockPrices, setStockPrices] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [timelineData, setTimelineData] = useState(null)

    useEffect(() => {
        if (firebase) {
            console.log("CALLING GETSTOCKPRICES")
            getStockPrices()
        }
    }, [firebase])

    async function test() {
        const result = await firebase
            .functions()
            .httpsCallable("DRgetStockPrices")({
                ticker: ticker,
                range: "max",
            })
            .then(x => x.data)
        return result
    }

    async function getStockPrices() {
        setLoading(true)

        const dynamicPrices = await firebase
            .firestore()
            .collection("stockPrices")
            .doc(ticker)
            .get()
            .then(doc =>
                doc.data().date.map((onedate, index) => ({
                    date: parseISO(onedate),
                    close: doc.data().close[index],
                }))
            )

        const dateArray = dynamicPrices.map(stockPrice => stockPrice.date)

        setTimelineData(
            timelineEvents
                .map((timelineEvent, index) => ({
                    date: dynamicPrices[closestIndexTo(parseISO(timelineEvent.date), dateArray)].date,
                    price: dynamicPrices[closestIndexTo(parseISO(timelineEvent.date), dateArray)].close,

                    headline: timelineEvent.headline,
                    event: timelineEvent.event.event,
                    cards: timelineEvent.cards,
                }))
                .sort(sort("date", "asc"))
        )

        await firebase
            .firestore()
            .collection("news")
            .doc(ticker)
            .get()
            .then(querySnapshot => {
                console.log(querySnapshot)
            })
        setStockPrices(dynamicPrices)

        setLoading(false)
        /*
        await firebase
            .firestore()
            .collection("news")
            .doc(ticker)
            .collection("2019-09-17")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(document => console.log(document.data()))
            })

        console.timeEnd("3")
*/

        /*
        console.time("IEX")

        const iexData = await fetch(
            "https://sandbox.iexapis.com/stable/stock/" +
                ticker +
                "/chart/" +
                "max" +
                "?token=Tpk_40d3f8806cf14e65b02c539920177af6&chartCloseOnly=true",
            { method: "GET" }
        )
            .then(x => x.json())
            .catch(err => alert(err))

        console.timeEnd("IEX")*/
    }

    return (
        <MainLayout
            header={
                <Header
                    commonData={commonData}
                    selectedCompany={selectedCompany}
                    selectedFeature={`TIMELINE`}
                    pathname={location.pathname}
                />
            }
            sidebar={<FeaturedArticlesSidebar title={"FEATURED ARTICLES"} featureds={featuredArticles} />}>
            <PageMetadata
                pageTitle={selectedCompany.shortName + " TIMELINE"}
                pageDescription={"Timeline of " + selectedCompany.shortName}
                pagePathname={location.pathname}
            />

            <ColumnTitle>{"TIMELINE OF " + selectedCompany.shortName.toUpperCase()}</ColumnTitle>

            <div>{loading ? <BeatLoader size={30} color={t.color.subtlegrey} /> : null}</div>

            {stockPrices && timelineData ? (
                <div>
                    <StockPriceChart
                        timelineData={timelineData}
                        ticker={ticker}
                        setSelected={setSelected}
                        stockPrices={stockPrices}
                    />

                    {timelineData
                        ? timelineData.find(obj => obj.date == selected)
                            ? timelineData.find(obj => obj.date == selected).cards && (
                                  <List
                                      items={timelineData
                                          .find(obj => obj.date == selected)
                                          .cards.map((card, index) => (
                                              <Card shadow content={card} key={selected + index} />
                                          ))}
                                      space={t.space[5]}
                                      nodivider
                                  />
                              )
                            : null
                        : null}
                </div>
            ) : null}

            <Button onClick={() => test()}>TEST GESTOCKPRICE TO DB</Button>
        </MainLayout>
    )
}

export default TimelinePage

/*

for stock chart:
get data from whereever. just some stock price chart. either dynamic, or store in google sheets, etc. probably wanna do dynamic - don't want to update this everyday.
get timeline data from contentful. this should have:
    - The date
    - Brief description of what happened
    - Link to the content that talks more about this
Render chart based on:
    - Line from stock price data
    - Scatter plot from contentful data
When click, let event handler set state. Render a card (or cards) based on the id or selected time.

*/

/*

await fetch("https://sandbox.iexapis.com/stable/stock/"+ticker+"/chart/"+range+"?token=T"+process.env.GATSBY_IEX_TOKEN, { body: JSON.stringify(data), method: "POST" })
    .then(x => x.json())
    .then(x => setTheMessage(x.message))

    */
