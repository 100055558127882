import React, { useState, useEffect, useContext } from "react"
import { css } from "@emotion/core"
import {
    VictoryBar,
    VictoryChart,
    VictoryLine,
    VictoryScatter,
    VictoryAxis,
    VictoryLabel,
    VictoryGroup,
    VictoryTooltip,
    VictoryBrushContainer,
    VictoryVoronoiContainer,
    VictoryCursorContainer,
    VictoryZoomContainer,
    LineSegment,
} from "victory"
import t, { mixin } from "theme"
import { getYear, getDayOfYear, format, subYears } from "date-fns"
import FirebaseContext from "components/Common/FirebaseContext"

function janFirsts(start, end) {
    let result = []
    const beginningYear = getYear(start) + (getDayOfYear(start) !== 1) * 1
    const endingYear = getYear(end)
    for (let i = beginningYear; i <= endingYear; i++) {
        result.push(new Date(i, 1, 1))
    }
    return result
}

const StockPriceChart = ({ stockPrices, ticker, setSelected, timelineData }) => {
    const firebase = useContext(FirebaseContext)

    const fullDomain = {
        x: [stockPrices[0].date, stockPrices[stockPrices.length - 1].date],
    }
    //need error checking for data points that are too far away.
    const [brushDomain, setBrushDomain] = useState({
        x: [Math.max(fullDomain.x[0], subYears(fullDomain.x[1], 1)), fullDomain.x[1]],
    })
    const [zoomDomain, setZoomDomain] = useState(brushDomain)
    const [verticalLine, setVerticalLine] = useState({
        x1: null,
        x2: null,
        y1: null,
        y2: null,
    })

    const [displayedPrice, setDisplayedPrice] = useState({
        price: stockPrices[stockPrices.length - 1].close,
        date: stockPrices[stockPrices.length - 1].date,
    })
    const fullJanFirsts = janFirsts(fullDomain.x[0], fullDomain.x[1])

    function handleBrush(domain) {
        setZoomDomain(domain)
    }

    function getData(fullData, domain) {
        const zoomedDomain = domain.x

        const startIndex = fullData.findIndex(d => d.date >= zoomedDomain[0])
        const endIndex = fullData.findIndex(d => d.date >= zoomedDomain[1])
        const filtered = fullData.slice(startIndex, endIndex)

        //        const maxPoints = Math.floor((200 * fullData.length) / (endIndex - startIndex))
        const maxPoints = 200

        if (filtered.length > maxPoints) {
            // limit k to powers of 2, e.g. 64, 128, 256
            // so that the same points will be chosen reliably, reducing flicker
            const k = Math.pow(2, Math.ceil(Math.log2(filtered.length / maxPoints)))

            return filtered.filter(
                // ensure modulo is always calculated from same reference: i + startIndex
                (d, i) => (i + startIndex) % k === 0
            )
        }

        return filtered
    }

    /*

                    */

    return (
        <div
            css={css`
                position: relative;

                ${mixin.noSelect}
            `}>
            <div
                css={css`
                    color: ${t.color.primary};
                    font-weight: ${t.font.semibold};
                    font-size: ${t.font.size[10]};
                `}>
                ${displayedPrice.price}
            </div>
            <div
                css={css`
                    color: ${t.color.lighttext};
                    font-size: ${t.font.size[3]};
                `}>
                {format(displayedPrice.date, "MMMM d, yyyy")}
            </div>
            <VictoryGroup
                padding={{ top: 20, left: 0, right: 0, bottom: 20 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiDimension="x"
                        onActivated={(points, props) => {
                            if (props.activePoints && props.activePoints.length > 0) {
                                setVerticalLine({
                                    x1: props.mousePosition.x,
                                    x2: props.mousePosition.x,
                                    y1: 0,
                                    y2: props.height,
                                })
                                if (props.activePoints[0].close) {
                                    setDisplayedPrice({
                                        price: props.activePoints[0].close,
                                        date: props.activePoints[0].date,
                                    })

                                    /*                                    firebase
                                        .functions()
                                        .httpsCallable("DRgetNews")({
                                            ticker: ticker,
                                            date: format(props.activePoints[0].date, "yyyy-MM-dd"),
                                        })
                                        .then(x => console.log(x))*/
                                }
                                props.activePoints.forEach(activePoint => {
                                    if (activePoint.headline) {
                                        setSelected(activePoint.date)
                                    }
                                })
                            }
                        }}
                    />
                }>
                {/*
                <VictoryAxis
                    fixLabelOverlap
                    tickValues={[zoomDomain.x[0], zoomDomain.x[1]]}
                    tickFormat={t => format(t, "MMM yy")}
                />
                    <VictoryAxis dependentAxis tickFormat={t => format(t, "$" + t)} />*/}

                <VictoryLine
                    data={getData(stockPrices, zoomDomain)}
                    x="date"
                    y="close"
                    style={{
                        data: {
                            stroke: t.color.primary,
                        },
                    }}
                />

                <VictoryScatter
                    data={getData(timelineData, zoomDomain)}
                    x="date"
                    y="price"
                    size={10}
                    style={{
                        data: { fill: t.color.subtlegrey, stroke: t.color.primary, fillOpacity: 1, strokeWidth: 3 },
                    }}
                    labels={({ datum }) => datum.headline}
                    labelComponent={<VictoryTooltip />}
                    events={[
                        {
                            target: "data",
                            eventHandlers: {
                                onClick: () => {
                                    return [
                                        {
                                            target: "data",
                                            mutation: props => {
                                                setSelected(props.datum["date"])
                                                return props.text === "clicked" ? null : { text: "clicked" }
                                            },
                                        },
                                    ]
                                },
                            },
                        },
                    ]}
                />
                <LineSegment
                    x1={verticalLine.x1}
                    x2={verticalLine.x2}
                    y1={verticalLine.y1}
                    y2={verticalLine.y2}
                    style={{
                        element: {
                            stroke: "red",
                        },
                    }}
                />
            </VictoryGroup>

            <VictoryChart
                height={150}
                padding={{ top: 0, left: 0, right: 0, bottom: 50 }}
                containerComponent={
                    <VictoryBrushContainer
                        brushDimension="x"
                        brushDomain={brushDomain}
                        onBrushDomainChange={domain => handleBrush(domain)}
                        brushStyle={{
                            fill: t.color.primary,
                            fillOpacity: 0.2,
                        }}
                    />
                }
                style={{
                    parent: {
                        borderTop: "1px solid grey",
                    },
                }}>
                <VictoryAxis
                    fixLabelOverlap
                    tickValues={fullJanFirsts}
                    tickFormat={t => (fullJanFirsts.length === 0 ? format(t, "MMM yy") : format(t, "yyyy"))}
                />
                <VictoryLine
                    data={getData(stockPrices, fullDomain)}
                    x="date"
                    y="close"
                    style={{
                        data: {
                            stroke: t.color.primary,
                        },
                    }}
                />
            </VictoryChart>
        </div>
    )
}

export default StockPriceChart

/*

    <VictoryZoomContainer
                        zoomDimension="x"
                        zoomDomain={zoomDomain}
                        onZ



                        <VictoryVoronoiContainer
                        voronoiDimension="x"
                        onActivated={(points, props) => {
                            console.log("points")
                            console.log(points)
                            console.log("props")
                            console.log(props)
                        }}
                    />


                       <VictoryCursorContainer
                        cursorDimension="x"
                        onCursorChange={(value, props) => {
                            console.log("value")
                            console.log(value)
                            console.log("props")
                            console.log(props)
                        }}
                        cursorComponent={
                            <LineSegment
                                style={{
                                    stroke: "grey",
                                }}
                            />
                        }
                    />

                */

/*
class App extends React.Component {
    constructor() {
        super()
        this.state = {}
    }

    handleZoom(domain) {
        this.setState({ selectedDomain: domain })
    }

    handleBrush(domain) {
        this.setState({ zoomDomain: domain })
    }

    render() {
        return (
            <div>
                <VictoryChart
                    width={600}
                    height={350}
                    scale={{ x: "time" }}
                    containerComponent={
                        <VictoryZoomContainer
                            responsive={false}
                            zoomDimension="x"
                            zoomDomain={this.state.zoomDomain}
                            onZoomDomainChange={this.handleZoom.bind(this)}
                        />
                    }>
                    <VictoryLine
                        style={{
                            data: { stroke: "tomato" },
                        }}
                        data={[
                            { x: new Date(1982, 1, 1), y: 125 },
                            { x: new Date(1987, 1, 1), y: 257 },
                            { x: new Date(1993, 1, 1), y: 345 },
                            { x: new Date(1997, 1, 1), y: 515 },
                            { x: new Date(2001, 1, 1), y: 132 },
                            { x: new Date(2005, 1, 1), y: 305 },
                            { x: new Date(2011, 1, 1), y: 270 },
                            { x: new Date(2015, 1, 1), y: 470 },
                        ]}
                    />
                </VictoryChart>

                <VictoryChart
                    padding={{ top: 0, left: 50, right: 50, bottom: 30 }}
                    width={600}
                    height={90}
                    scale={{ x: "time" }}
                    containerComponent={
                        <VictoryBrushContainer
                            responsive={false}
                            brushDimension="x"
                            brushDomain={this.state.selectedDomain}
                            onBrushDomainChange={this.handleBrush.bind(this)}
                        />
                    }>
                    <VictoryAxis
                        tickValues={[
                            new Date(1985, 1, 1),
                            new Date(1990, 1, 1),
                            new Date(1995, 1, 1),
                            new Date(2000, 1, 1),
                            new Date(2005, 1, 1),
                            new Date(2010, 1, 1),
                        ]}
                        tickFormat={x => new Date(x).getFullYear()}
                    />
                    <VictoryLine
                        style={{
                            data: { stroke: "tomato" },
                        }}
                        data={[
                            { x: new Date(1982, 1, 1), y: 125 },
                            { x: new Date(1987, 1, 1), y: 257 },
                            { x: new Date(1993, 1, 1), y: 345 },
                            { x: new Date(1997, 1, 1), y: 515 },
                            { x: new Date(2001, 1, 1), y: 132 },
                            { x: new Date(2005, 1, 1), y: 305 },
                            { x: new Date(2011, 1, 1), y: 270 },
                            { x: new Date(2015, 1, 1), y: 470 },
                        ]}
                    />
                </VictoryChart>
            </div>
        )
    }
}
ReactDOM.render(<App />, mountNode)

*/

/*
<List
items={timelineData
    .find(obj => obj.date == selected)
    .cards.map((card, index) => (
        <Card shadow content={card} key={selected + index} />
    ))}
space={t.space[5]}
nodivider
/>
*/

/*


class DRTooltip extends React.Component {
    render() {
        const { x, y, orientation } = this.props
        const newY = orientation === "bottom" ? y - 35 : y + 35
        return (
            <>
                <g>
                    <circle cx={x} cy={newY} r="20" stroke="tomato" fill="none" />
                    <circle cx={x} cy={newY} r="25" stroke="orange" fill="none" />
                    <circle cx={x} cy={newY} r="30" stroke="gold" fill="none" />
                </g>
            </>
        )
    }
}

*/
